@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.reviews {
	background-color: $light-green;
}

.content {
	text-align: center;
}

.trustpilot {
	font-size: 1.8rem;
	margin-bottom: 20px;
	vertical-align: middle;

	.trustpilotImage {
		width: 200px;
		margin-left: 5px;
		height: 23px;
		position: relative;
		top: 5px;
		background-image: url(/images/img-trustpilot.png);
		display: inline-block;
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.reviewsHeading {
	margin-bottom: 10px;
}

.itemWrapper {
	width: 207px;
	flex-basis: 207px;
	flex-shrink: 0;
	text-align: left;
}

.item {
	margin-bottom: 0;
	background: $white;
	display: flex;
	flex-direction: column;
	padding: 20px;
	height: auto;
	cursor: pointer;
	user-select: none;
	text-align: left;

	.image {
		width: 41px;
		height: 41px;
		overflow: hidden;
		position: relative;
		border-radius: 100%;
		display: inline-block;
		background-color: $light-green;

		@include tablet() {
			width: 40px;
			height: 40px;
		}
		.initials {
			width: 100% !important;
			text-align: center;
			top: 3px !important;
			position: absolute !important;
			font-weight: $bold-base;
			font-size: 20px;
			line-height: 24px;
			padding: 6px 0 !important;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.name {
		display: inline-block;
		color: $deep-green;
		font-weight: $normal-base;
		font-size: 1.4rem;
		line-height: 1.6rem;
		margin-top: 5px;
		position: relative;
	}
	.stars {
		position: relative;
		width: 120px;
		height: 24px;
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center center;
		margin-bottom: 10px;
		margin-top: 3px;
	}

	.title {
		font-weight: bold;
		display: block;
		margin: 0px 0 10px;
		font-weight: $bold-base;
		font-size: 1.6rem;
		line-height: 2rem;
		color: $deep-green;
	}

	.content {
		font-weight: $normal-base;
		font-size: 16px;
		line-height: 20px;
		color: $deep-green;
		margin-bottom: 0;
		text-align: left;
	}
}
